import React from "react";
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const News = ({ notificationsToDisplay }) => {
    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = notificationsToDisplay.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep === maxSteps - 1 ? 0 : prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep === 0 ? maxSteps - 1 : prevActiveStep - 1);
    };
    return (
        <Box sx={{ width: '80%', flexGrow: 1, m: "0vw auto 8vw auto" }}>
            <Box sx={{ width: '100%' }}>
                <Typography variant="h3" border={"none"} align="center" p='2vw 0 0 0' fontSize={"3vw"}>
                    {maxSteps > 0 ? notificationsToDisplay[activeStep].notificationHeadline : "No notifications to display."}
                </Typography>
            </Box>
            <MobileStepper
                variant="dots"
                steps={maxSteps}
                position="static"
                activeStep={activeStep}
                sx={{
                    width: '90%',
                    flexGrow: 1,
                    background: 'none',
                    m: "auto",
                    '& .MuiMobileStepper-dot': {
                        backgroundColor: "#ffffb585", // Set color for all dots
                    },
                    '& .MuiMobileStepper-dotActive': {
                        backgroundColor: "#ffff00", // Set color for active dot
                    }
                }}
                nextButton={
                    <Button onClick={handleNext}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft fontSize="large" sx={{ color: '#ffff0080' }} />
                        ) : (
                            <KeyboardArrowRight fontSize="large" sx={{ color: '#ffff0080' }} />
                        )}
                    </Button>
                }
                backButton={
                    <Button onClick={handleBack}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight fontSize="large" sx={{ color: '#ffff0080' }} />
                        ) : (
                            <KeyboardArrowLeft fontSize="large" sx={{ color: '#ffff0080' }} />
                        )}
                    </Button>
                }
            />
        </Box>);
};

export default News;
