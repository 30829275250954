import React, { useState, useEffect } from "react";
import Event from "../components/event";

function Artiste({ events }) {
  const [categories, setCategories] = useState([]);
  const [sortedEvents, setSortedEvents] = useState(events);
  const [isActive, setIsActive] = useState("TOUS");
  const [isSelect, setIsSelect] = useState("jour");

  useEffect(() => {
    const allCategories = [
      // ...new Set(events.map((event) => event.categories[0].name)),
      ...new Set(events.map((event) => event.musicalGenre)),
    ].sort((a, b) => a.localeCompare(b));
    setCategories(allCategories);
  }, [events]);

  const daySortEvents = () => {
    const daySortedEvents = [...sortedEvents];
    daySortedEvents.sort(
      // (a, b) => new Date(a.start_date) - new Date(b.start_date)
      (a, b) => new Date(a.startDate) - new Date(b.startDate)
    );
    setSortedEvents(daySortedEvents);
    handleSortClick("jour");
  };
  const azSortEvents = () => {
    const azSortedEvents = [...sortedEvents];
    // azSortedEvents.sort((a, b) => a.title.localeCompare(b.title));
    azSortedEvents.sort((a, b) => a.artist.artistName.localeCompare(b.artist.artistName));
    setSortedEvents(azSortedEvents);
    handleSortClick("nom");
  };
  const categoryFilterEvents = (cat) => {
    if (cat === "TOUS" && isSelect === "jour") {
      setSortedEvents(events);
      handleCategoryClick("TOUS");
    } else if (cat === "TOUS" && isSelect === "nom") {
      // setSortedEvents(events.sort((a, b) => a.title.localeCompare(b.title)));
      setSortedEvents(events.sort((a, b) => a.artist.artistName.localeCompare(b.artist.artistName)));
      handleCategoryClick("TOUS");
    } else if (cat !== "TOUS" && isSelect === "jour") {
      const filteredEvents = events.filter((item) =>
        // item.categories[0].name.includes(cat)
        item.musicalGenre.includes(cat)
      );
      setSortedEvents(filteredEvents);
      handleCategoryClick(cat);
    } else {
      const filteredEvents = events
        // .filter((item) => item.categories[0].name.includes(cat))
        .filter((item) => item.musicalGenre.includes(cat))
        // .sort((a, b) => a.title.localeCompare(b.title));
        .sort((a, b) => a.artist.artistName.localeCompare(b.artist.artistName));
      setSortedEvents(filteredEvents);
      handleCategoryClick(cat);
    }
  };
  const handleCategoryClick = (key) => {
    setIsActive(key);
  };
  const handleSortClick = (id) => {
    setIsSelect(id);
  };

  return (
    <div className="content">
      <div className="filter-container">
        <button
          className={`button ${isSelect === "jour" ? "selected" : ""} `}
          onClick={daySortEvents}
        >
          PAR JOUR
        </button>
        <button
          className={`button ${isSelect === "nom" ? "selected" : ""} `}
          onClick={azSortEvents}
        >
          PAR NOM
        </button>
      </div>
      <div className="categories-container">
        <div className="category-all-container">
          <button
            key="TOUS"
            onClick={() => {
              categoryFilterEvents("TOUS");
            }}
            className={`category category-all ${
              isActive === "TOUS" ? "selected" : ""
            }`}
          >
            TOUS
          </button>
        </div>
        <div className="categories-carousel">
          {categories.map((category) => (
            <button
              className={`category category-carousel uppercase ${
                isActive === category ? "selected" : ""
              }`}
              key={category}
              onClick={() => {
                categoryFilterEvents(category);
              }}
            >
              {category}
            </button>
          ))}
        </div>
      </div>
      <div className="events-container">
        {sortedEvents.map((item) => (
          <Event event={item} key={item.id} />
        ))}
      </div>
    </div>
  );
}
export default Artiste;
