import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaYoutube,
  FaTiktok,
  FaSpotify,
} from "react-icons/fa6";
import { BsInstagram } from "react-icons/bs";

const Footer = () => {
  return (
    <footer>
      <div className="social-container">
        <div>
          <FaFacebookF className="social" />
          <FaTwitter className="social" />
          <BsInstagram className="social" />
        </div>
        <div>
          <FaYoutube className="social" />
          <FaTiktok className="social" />
          <FaSpotify className="social" />
        </div>
      </div>
      <div className="footer">
        <div className="privacy">
          <div>Mentions Légales</div>
          <div>CGU</div>
        </div>
        <div>
          <span className="copyright">NATION SOUND</span> &copy; 2024
        </div>
      </div>
    </footer>
  );
};
export default Footer;
