import React, { useState, useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import axios from "axios";
import "mapbox-gl/dist/mapbox-gl.css";
import MarkerFilter from "../components/markerFilter";
import { FiFilter } from "react-icons/fi";
import { MdGpsFixed } from "react-icons/md";
import "../styles/carte.css";

mapboxgl.accessToken =
  "pk.eyJ1IjoibHVkb3BvIiwiYSI6ImNsbWl5YXZhdDJzcnEzZGpya2Jwb3BxN2wifQ.GK7bfSxMOIrnLFiWiYr9cw";

function Carte({ locations }) {
  const marker_location = locations.map((item) => ({
    // name: item.title.rendered,
    name: item.locationName,
    // category: item.acf.category,
    category: item.locationCategory.toLowerCase().replace(/\s+/g, '-'),
    // lng: item.acf.lnglat.lng,
    lng: item.longitude,
    // lat: item.acf.lnglat.lat,
    lat: item.latitude,
    // icon: item.acf.icon,
    icon: "https://admin.nationsound.online/uploads/icons/" + item.locationIcon,
    // description: item.acf.description,
    description: item.locationCategory,
  }));
  const [markerCategories, setMarkerCategories] = useState([]);
  const [markerIcons, setMarkerIcons] = useState([]);
  const [displayFilter, setDisplayFilter] = useState(false);
  const [allChecked, setAllChecked] = useState(true);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [myMap, setMyMap] = useState(null);
  const startRef = useRef(getRandomPosition([2.42, 2.45], [48.82, 48.84]));
  // const start = startRef.current;
  const isGPSActiveRef = useRef(false);

  const toggleFilter = () => {
    setDisplayFilter(!displayFilter);
  };
  const handleCategoryToggle = (e) => {
    const id = e.target.id;
    if (id === "tous") {
      setAllChecked(true);
      markerCategories.map((e) => (document.getElementById(e).checked = false));
      setSelectedCategories([]);
    } else {
      setAllChecked(false);
      if (!selectedCategories.includes(id)) {
        setSelectedCategories([...selectedCategories, id]);
      } else {
        setSelectedCategories(
          selectedCategories.filter((category) => category !== id)
        );
      }
    }
  };
  function getRandomPosition(lngRange, latRange) {
    const lngStart = Math.random() * (lngRange[1] - lngRange[0]) + lngRange[0];
    const latStart = Math.random() * (latRange[1] - latRange[0]) + latRange[0];
    const position = [lngStart, latStart];
    return position;
  }
  const getRoute = async (end, map) => {
    const start = startRef.current;
    try {
      const response = await axios.get(
        `https://api.mapbox.com/directions/v5/mapbox/walking/${start[0]},${start[1]};${end[0]},${end[1]}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`
      );
      // console.log(
      //   `https://api.mapbox.com/directions/v5/mapbox/walking/${start[0]},${start[1]};${end[0]},${end[1]}?steps=true&geometries=geojson&access_token=${mapboxgl.accessToken}`
      // );
      const data = response.data.routes[0];
      const route = data.geometry.coordinates;
      const geojson = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "LineString",
          coordinates: route,
        },
      };
      if (map) {
        if (map.getSource("route")) {
          map.getSource("route").setData(geojson);
        } else {
          map.addLayer({
            id: "route",
            type: "line",
            source: {
              type: "geojson",
              data: geojson,
            },
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#3887be",
              "line-width": 5,
              "line-opacity": 0.75,
            },
          });
        }
      } else {
        console.error("Map is not initialized.");
      }
    } catch (error) {
      console.error("Error fetching route:", error);
    }
  };

  useEffect(() => {
    if (selectedCategories.length === 0) {
      setAllChecked(true);
    }
    const mapElements = Array.from(
      document.getElementById("map").getElementsByClassName("marker")
    );
    mapElements.forEach((element) => {
      const classList = element.classList.value;
      const pattern = new RegExp(selectedCategories.join("|"));
      if (classList.match(pattern)) {
        element.style.display = "block";
      } else {
        element.style.display = "none";
      }
    });
  }, [selectedCategories]);

  useEffect(() => {
    const markerCategories = [
      ...new Set(
        marker_location
          .filter((cat) => cat.category !== "camp")
          .map((category) => category.category)
      ),
    ];
    setMarkerCategories(markerCategories);
    // const markerIcons = [
    //   ...new Set(
    //     marker_location
    //       .filter((cat) => cat.category !== "camp")
    //       .map((icon) => icon.icon)
    //   ),
    // ];
    const marker_icons = {}
    markerCategories.forEach(category => {
      const location = marker_location.find(location => location.category === category);
      if (location) {
        marker_icons[category] = location.icon;
      }
    });
    const markerIcons=Object.values(marker_icons);
    setMarkerIcons(markerIcons);
  }, [locations]);

  useEffect(() => {
    const bounds = [
      [2.41, 48.81],
      [2.46, 48.85],
    ];
    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/ludopo/clmq0nzex021l01pb6wphgzvt",
      center: [2.432, 48.83],
      zoom: 14,
      maxBounds: bounds,
    });
    marker_location.map((location) => {
      const customMarker = document.createElement("img");
      // customMarker.className = "marker " + location.category + "-marker ";
      customMarker.className = "marker " + location.category + "-marker";
      customMarker.src = `${location.icon}`;
      const marker = new mapboxgl.Marker({ element: customMarker })
        .setLngLat([location.lng, location.lat])
        .setPopup(
          new mapboxgl.Popup({
            closeButton: false,
            className: "stage_popup",
          }).setText(`${location.name}`)
        );
      marker.addTo(map);
      return marker;
    });
    map.on("load", () => {
      setMapLoaded(true);
      setMyMap(map);
    });
    map.on("click", (event) => {
      if (isGPSActiveRef.current) {
        const coords = Object.keys(event.lngLat).map(
          (key) => event.lngLat[key]
        );
        const end = {
          type: "FeatureCollection",
          features: [
            {
              type: "Feature",
              properties: {},
              geometry: {
                type: "Point",
                coordinates: coords,
              },
            },
          ],
        };
        if (map.getLayer("end")) {
          map.getSource("end").setData(end);
        } else {
          map.addLayer({
            id: "end",
            type: "circle",
            source: {
              type: "geojson",
              data: {
                type: "FeatureCollection",
                features: [
                  {
                    type: "Feature",
                    properties: {},
                    geometry: {
                      type: "Point",
                      coordinates: coords,
                    },
                  },
                ],
              },
            },
            paint: {
              "circle-radius": 10,
              "circle-color": "#f30",
            },
          });
        }
        getRoute(coords, map);
      }
    });
    return () => map.remove();
  }, []);

  const handleGpsButtonClick = () => {
    const buttonGPS = document.querySelector(".display-position");
    if (myMap) {
      if (myMap.getLayer("point")) {
        myMap.removeLayer("point");
        if (myMap.getLayer("end")) {
          myMap.removeLayer("end");
        }
        if (myMap.getLayer("route")) {
          myMap.removeLayer("route");
        }
        if (myMap.getSource("point")) {
          myMap.removeSource("point");
        }
        if (myMap.getSource("end")) {
          myMap.removeSource("end");
        }
        if (myMap.getSource("route")) {
          myMap.removeSource("route");
        }
        isGPSActiveRef.current = false;
        buttonGPS.classList.remove("button-gps-on");
        startRef.current = getRandomPosition([2.42, 2.45], [48.82, 48.84]);
      } else {
        const start = startRef.current;
        isGPSActiveRef.current = true;
        buttonGPS.classList.add("button-gps-on");
        getRoute(start, myMap);
        myMap.addLayer({
          id: "point",
          type: "circle",
          source: {
            type: "geojson",
            data: {
              type: "FeatureCollection",
              features: [
                {
                  type: "Feature",
                  properties: {},
                  geometry: {
                    type: "Point",
                    coordinates: start,
                  },
                },
              ],
            },
          },
          paint: {
            "circle-radius": 10,
            "circle-color": "#3887be",
          },
        });
      }
    }
  };

  return (
    <div className="map-container">
      <div className="map-wrapper">
        {mapLoaded && (
          <button className="display-position " onClick={handleGpsButtonClick}>
            <MdGpsFixed />
          </button>
        )}
        <button className="display-filter" onClick={toggleFilter}>
          <FiFilter />
        </button>
        {displayFilter && (
          <MarkerFilter
            markerCategories={markerCategories}
            markerIcons={markerIcons}
            handleCategoryToggle={handleCategoryToggle}
            allChecked={allChecked}
          />
        )}
        <div id="map">{/* map */}</div>
      </div>
    </div>
  );
}
export default Carte;
