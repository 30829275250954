import React from "react";
import { NavLink } from "react-router-dom";
import "../styles/event.css";

function Event({ event }) {
  return (
    <div className="event-container">
      <div>
        {/* <img src={event.image.url} className="event-img" alt={event.title} /> */}
        <img src={event.artist.imageUrls.original} className="event-img" alt={event.artist.artistName} />
      </div>
      <div className="event-abstract">
        {/* <NavLink to={`/${event.slug}`} className="to-detail"> */}
        <NavLink to={`/${event.artist.slug}`} className="to-detail">
          {/* <div className="event-title">{event.title}</div> */}
          <div className="event-title">{event.artist.artistName}</div>
        </NavLink>
        <div>
          {/* {new Date(event.start_date).toLocaleString("fr", {
            day: "numeric",
            month: "long",
          })} */}
          {new Date(event.startDate).toLocaleString("fr", {
            day: "numeric",
            month: "long",
          })}

        </div>
      </div>
    </div>
  );
}
export default Event;
