import React, { useMemo } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
//
import "./styles/index.css";
import "./styles/font.css";
//
import Marquee from "./components/marquee";
import Navbar from "./components/navigation";
import Accueil from "./routes/Accueil";
import Carte from "./routes/Carte";
import Infos from "./routes/Infos";
import Partenaires from "./routes/Partenaires";
import Programmation from "./routes/Programmation";
import Planning from "./routes/Planning";
import Detail from "./routes/Detail";
import Footer from "./components/footer";
import ScrollToTop from "./components/scrollToTop";

import reportWebVitals from "./reportWebVitals";
import News from "./components/news";

function useMercure(setRawNotificationsData) {
  useEffect(() => {
    const url = new URL('https://3471c0e1-59fc-4042-aa3f-6eb82ff81394.mercure.rocks/.well-known/mercure');
    const topics = ['https://nationsound.online/notifications/updates'];
    topics.forEach(topic => url.searchParams.append('topic', topic));
    const eventSource = new EventSource(url.toString(), { withCredentials: true });
    const eventHandler = (event) => {
      const newData = JSON.parse(event.data);
      console.log('Received data:', newData);
      setRawNotificationsData(newData);
    };
    eventSource.onmessage = eventHandler;
    return () => {
      eventSource.close();
    };
  }, [setRawNotificationsData]);
}

function App() {
  const [events, setEvents] = useState([]);
  const [isEventsLoading, setIsEventsLoading] = useState(true);
  const [locations, setLocations] = useState([]);
  const [isLocationsLoading, setIsLocationsLoading] = useState(true);

  //Notifications
  const [rawNotificationsData, setRawNotificationsData] = useState([]);
  useMercure(setRawNotificationsData);

  const fetchNotifications = () => {
    axios
      .get("https://admin.nationsound.online/api/notifications")
      .then((res) => {
        setRawNotificationsData(res.data);
      });
  };
  useEffect(() => {
    fetchNotifications();
  }, []);

  const notificationsData = useMemo(() => rawNotificationsData, [rawNotificationsData]);
  const notificationOnMarquee = useMemo(() => rawNotificationsData.filter(n => n.isMarquee))

  const fetchEvents = () => {
    axios
      .get("https://admin.nationsound.online/api/events")
      .then((res) => {
        setEvents(res.data);
        setIsEventsLoading(false);
      });
  };
  useEffect(() => {
    fetchEvents();
  }, []);
  const fetchLocations = () => {
    axios
      .get("https://admin.nationsound.online/api/locations")
      .then((res) => {
        setLocations(res.data);
        setIsLocationsLoading(false);
      });
  };
  useEffect(() => {
    fetchLocations();
  }, []);
  if (!isEventsLoading && !isLocationsLoading) {
    return (
      <React.StrictMode>
        <Router>
          {notificationOnMarquee.length > 0 && (
            <Marquee notificationOnMarquee={notificationOnMarquee} />
          )}
          <Navbar hasMarquee={notificationOnMarquee.length > 0} />
          {notificationsData.length > 0 && (
            <News notificationsToDisplay={notificationsData} />
          )}
          <ScrollToTop>
            <Routes>
              <Route path="/" element={<Accueil events={events} />} />
              <Route path="Carte" element={<Carte locations={locations} />} />
              <Route path="Infos" element={<Infos />} />
              <Route path="Partenaires" element={<Partenaires />} />
              <Route
                path="Programmation"
                element={<Programmation events={events} />}
              />
              <Route
                path="Programmation/Planning"
                element={<Planning events={events} />}
              />
              <Route path=":detail" element={<Detail events={events} />} />
            </Routes>
          </ScrollToTop>
          <Footer />
        </Router>
      </React.StrictMode>
    );
  }
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
