import Day from "../components/day";
import "../styles/planning.css";

function Timetable({ events }) {
  // const stages = [...new Set(events.map((event) => event.tags[0].name))].sort(
    const stages = [...new Set(events.map((event) => event.stage))].sort(
    (a, b) => a.localeCompare(b)
  );
  const eventsByStage = (stg) => {
    const stagedEvents = events.filter((item) =>
      // item.tags[0].name.includes(stg)
      item.stage.includes(stg)
    );
    return stagedEvents;
  };
  return (
    <div className="planning">
      {stages.map((stage) => (
        <div key={stage}>
          <h2 className="h2-timetable">Scène {stage}</h2>
          <Day stagedEvents={eventsByStage(stage)} />
        </div>
      ))}
    </div>
  );
}

export default Timetable;
