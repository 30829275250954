import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../styles/navigation.css";

const links = [
  { path: "/", text: "Accueil" },
  { path: "Programmation", text: "Programmation" },
  { path: "Carte", text: "Carte" },
  { path: "Infos", text: "Infos" },
  {
    path: "https://www.seetickets.com/fr/festival-tickets",
    text: "Billeterie",
  },
  { path: "Partenaires", text: "Partenaires" },
];

const Navbar = ({hasMarquee}) => {
  const [isOpen, setOpen] = useState(false);
  isOpen
    ? (document.body.style.overflow = "hidden")
    : (document.body.style.overflow = "visible");
  return (
    <header className={`navbar ${hasMarquee ? "marquee-padding" : ""}`} role="navigation" aria-label="main navigation">
      <div className="menu">
        <NavLink
          to="#"
          className={`menu-toggle ${isOpen ? " xmark" : ""}`}
          onClick={() => setOpen((prev) => !prev)}
        >
          <span
            className={`menu-toggle-bar menu-toggle-bar--top ${isOpen ? " xmark" : ""
              }`}
          ></span>
          <span
            className={`menu-toggle-bar menu-toggle-bar--middle ${isOpen ? " xmark" : ""
              }`}
          ></span>
          <span
            className={`menu-toggle-bar menu-toggle-bar--bottom ${isOpen ? " xmark" : ""
              }`}
          ></span>
        </NavLink>
      </div>
      <div className="container">
        <div className="text-center">
          <NavLink to="/">
            <h1>Nation Sound</h1>
          </NavLink>
        </div>
        <div className={`menu-bg ${isOpen ? " show-bg" : ""}`}></div>
        <nav className={`menu-nav ${isOpen ? " show-menu" : ""}`}>
          <ul className="nav-list">
            {links.map((link) => (
              <li key={link.text}>
                {link.path.startsWith("http") ? (
                  <a href={link.path} target="_blank" rel="noopener noreferrer">
                    {link.text}
                  </a>
                ) : (
                  <NavLink to={link.path} onClick={() => setOpen(false)}>
                    {link.text}
                  </NavLink>
                )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};
export default Navbar;
