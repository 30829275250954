import React from "react";
import { NavLink } from "react-router-dom";

function Day({ stagedEvents }) {
  // const days = [...new Set(stagedEvents.map((e) => e.start_date.slice(0, 10)))];
  const days = [...new Set(stagedEvents.map((e) => e.startDate.slice(0, 10)))];
  const eventsPerDay = (day) => {
    const dayEvents = stagedEvents.filter((item) =>
      // item.start_date.includes(day)
      item.startDate.includes(day)
    );
    return dayEvents;
  };
  return (
    <div className="stage">
      {days.map((day) => (
        <div key={day} className="day">
          <h3>
            {new Date(day).toLocaleString("fr", {
              weekday: "long",
              day: "numeric",
              month: "long",
            })}
          </h3>
          {eventsPerDay(day).map((event) => (
            <div key={event.id} className="planned-event">
              {/* <NavLink
                to={`/${event.slug}`}
                className="to-detail-from-day"
              ></NavLink> */}
              <NavLink
                to={`/${event.artist.slug}`}
                className="to-detail-from-day"
              ></NavLink>
              <div className="planned-event-thumbnail">
                {/* <img src={event.image.sizes.thumbnail.url} /> */}
                <img src={event.artist.imageUrls.thumbnail} alt={event.artist.artistName}/>
              </div>
              <div className="planned-event-detail">
                {/* <div>{event.title}</div> */}
                <div>{event.artist.artistName}</div>
                <div>
                  {/* {event.start_date.slice(11, 16)} */}
                  {event.startDate.slice(11, 16)}
                  &nbsp;&rarr;&nbsp;
                  {/* {event.end_date.slice(11, 16)} */}
                  {event.endDate.slice(11, 16)}
                </div>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
export default Day;
