import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import parse from "html-react-parser";
import "../styles/detail.css";

function Detail({ events }) {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };
  const location = useLocation();
  // const detail = events.filter((e) => e.slug === location.pathname.slice(1));
  const detail = events.filter((e) => e.artist.slug === location.pathname.slice(1));
  // const imgSrc = detail[0].image.sizes.medium_large
  //   ? detail[0].image.sizes.medium_large.url
  //   : detail[0].image.url;
  const imgSrc = detail[0].artist.imageUrls.medium

  return (
    <div>
      <div>
        <button className="goBack" onClick={goBack}>
          &larr; Retour
        </button>
      </div>
      <div className="detail-wrap">
        <div className="detail-img">
          {/* <img src={imgSrc} alt={detail[0].title} /> */}
          <img src={imgSrc} alt={detail[0].artist.artistName} />
        </div>
        <div>
          <div className="detail-content">
            {/* <h2>{detail[0].title}</h2> */}
            <h2 className="uppercase">{detail[0].artist.artistName}</h2>
            <div>
              {/* <span>{detail[0].categories[0].name}</span> */}
              <span className="uppercase">{detail[0].musicalGenre}</span>
            </div>
            <div className="detail-planned">
              <div>
                {/* {new Date(detail[0].start_date).toLocaleString("fr", {
                  weekday: "long",
                  day: "numeric",
                  month: "long",
                })} */}
                {new Date(detail[0].startDate).toLocaleString("fr", {
                  weekday: "long",
                  day: "numeric",
                  month: "long",
                })}
              </div>
              {/* <div>
                {detail[0].start_date.slice(11, 16)}
                &nbsp;&rarr;&nbsp;
                {detail[0].end_date.slice(11, 16)}
                <br />
                Scène&nbsp;{detail[0].tags[0].name}
              </div> */}
              <div>
                {detail[0].startDate.slice(11, 16)}
                &nbsp;&rarr;&nbsp;
                {detail[0].endDate.slice(11, 16)}
                <br />
                Scène&nbsp;{detail[0].stage}
              </div>
            </div>
            {/* {parse(detail[0].excerpt)}
            {parse(detail[0].description)} */}
            <p>{parse(detail[0].artist.excerpt)}</p>
            {parse(detail[0].artist.description)}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Detail;
