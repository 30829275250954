import React from "react";

function MarkerFilter({
  markerCategories,
  markerIcons,
  allChecked,
  handleCategoryToggle,
}) {
  return (
    <div className="filter">
      <div key="tous">
        <label>
          <input
            id="tous"
            type="checkbox"
            value="tous"
            checked={allChecked}
            onChange={handleCategoryToggle}
          />
          <span>TOUS</span>
        </label>
      </div>
      {markerCategories.map((category, index) => (
        <div key={category} className="item-filter">
          <label>
            <input
              id={category}
              type="checkbox"
              value={category}
              onChange={handleCategoryToggle}
            />
            <img
              src={markerIcons[index]}
              className={`marker ${category}-marker`}
            />
          </label>
        </div>
      ))}
    </div>
  );
}

export default MarkerFilter;
