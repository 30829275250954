import React from "react";
import { NavLink } from "react-router-dom";
import Artiste from "../components/Artiste";
import "../styles/programmation.css";

function Programmation({ events }) {
  return (
    <div className="content">
      <h2>PROGRAMMATION</h2>
      <div className="filter-container">
        <button
          className="button"
          style={{ backgroundColor: "#ffffb5", color: " #0b0426" }}
        >
          ARTISTE
        </button>
        <NavLink to="Planning" className="button to-planning">
          PLANNING
        </NavLink>
      </div>
      <div>
        <Artiste events={events} />
      </div>
    </div>
  );
}

export default Programmation;
