import React from "react";
import "../styles/notification.css";

const Marquee = ({ notificationOnMarquee }) => {
    const displayText = notificationOnMarquee[0].notificationHeadline;
    return (
        <div className="marquee-container">
            <div className="marquee">
                <div>
                    <span>{displayText}&nbsp;</span>
                    <span>{displayText}&nbsp;</span>
                </div>
            </div>
        </div>
    );
};

export default Marquee;
