import React from "react";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import epsi from "../image/epsi.svg";
import bandcamp from "../image/bandcamp.svg";
import deezer from "../image/deezer.svg";
import spotify from "../image/spotify.svg";
import twitch from "../image/twitch.svg";
import wwf from "../image/wwf.svg";

function Accueil({ events }) {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 4000,
    cssEase: "linear",
  };
  return ( 
    <div>
      <div className="main-container">
        <div>
          <h2>Découvrez le plus grand festival de musique actuelle</h2>
        </div>
        <div className="bubble-container">
          <div className="bubble-artiste">
            <svg width="100%" height="100%" viewBox="0 0 100 100">
              <defs>
                <mask id="hole-mask">
                  <rect x="0" y="0" width="100" height="100" fill="white" />
                  <circle cx="50" cy="50" r="39.5" fill="black" />
                </mask>
              </defs>
              <rect
                x="0"
                y="0"
                width="100"
                height="100"
                fill="#0b0426"
                mask="url(#hole-mask)"
              />
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 100 100"
                fill="none"
                stroke="#ffffb5"
                stroke-width="0.5"
              >
                <path
                  id="curve"
                  d="M10,50 A40,40 0 0,1 50,10 A40,40 0 0,1 90,50 A40,40 0 0,1 50,90 A40,40 0 0,1 10,50"
                />
                <text width="100%">
                  <textPath href="#curve">ARTISTES</textPath>
                </text>
              </svg>
            </svg>
            <div className="artiste-container">
              <Slider {...settings}>
                {events.map((item, index) => (
                  <div key={index}>
                    <div
                      className="slide-background"
                      // style={{ backgroundImage: `url(${item.image.url})` }}
                      style={{ backgroundImage: `url(${item.artist.imageUrls.original})` }}
                    >
                      {/* <h3 className="h3-accueil">{item.title}</h3> */}
                      <h3 className="h3-accueil">{item.artist.artistName}</h3>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
            <div className="bubble target-artist">
              <NavLink to="Programmation" className="target"></NavLink>
            </div>
          </div>
          <div className="bubble-stage">
            <svg width="100%" height="100%" viewBox="0 0 100 100">
              <defs>
                <mask id="hole-mask">
                  <rect x="0" y="0" width="100" height="100" fill="white" />
                  <circle cx="50" cy="50" r="39.5" fill="black" />
                </mask>
              </defs>
              <rect
                x="0"
                y="0"
                width="100"
                height="100"
                fill="#0b0426"
                mask="url(#hole-mask)"
              />
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 100 100"
                fill="none"
                stroke="#ffffb5"
                stroke-width="0.5"
              >
                <path
                  id="curve-stage"
                  d="M50,10 A40,40 0 0,1 90,50 A40,40 0 0,1 50,90 A40,40 0 0,1 10,50 A40,40 0 0,1 50,10"
                />
                <text width="100%">
                  <textPath href="#curve-stage">SCENES ET CONCERTS</textPath>
                </text>
              </svg>
            </svg>
            <div className="stage-container">
              <p>Retrouvez ici le planning du festival</p>
            </div>
            <div className="bubble target-stage">
              <NavLink to="Programmation/Planning" className="target"></NavLink>
            </div>
          </div>
          <div className="bubble-carte">
            <svg width="100%" height="100%" viewBox="0 0 100 100">
              <defs>
                <mask id="hole-mask">
                  <rect x="0" y="0" width="100" height="100" fill="white" />
                  <circle cx="50" cy="50" r="39.5" fill="black" />
                </mask>
              </defs>
              <rect
                x="0"
                y="0"
                width="80"
                height="100"
                fill="#0b0426"
                mask="url(#hole-mask)"
              />
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 100 100"
                fill="none"
                stroke="#ffffb5"
                stroke-width="0.5"
              >
                <path
                  id="curve-map"
                  d="M10,50 A40,40 0 0,1 50,10 A40,40 0 0,1 90,50 A40,40 0 0,1 50,90 A40,40 0 0,1 10,50"
                />
                <text x="43" y="43" width="100%">
                  <textPath href="#curve-map">CARTE</textPath>
                </text>
              </svg>
            </svg>
            <div className="carte-container"></div>
            <div className="bubble target-carte">
              <NavLink to="Carte" className="target"></NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-stardust">
        <div>
          <h2>Des questions ?</h2>
          <NavLink to="Infos" className="to-infos">
            TROUVER LA REPONSE
          </NavLink>
        </div>
        <div className="space">
          <h2>Je veux des billets !</h2>
          <a
            href="https://www.seetickets.com/fr/festival-tickets"
            target="_blank"
            rel="noreferrer"
            className="to-infos"
          >
            C'EST ICI
          </a>
        </div>
      </div>
      <div>
        <h2 className="partners">Nos Partenaires</h2>
        <div className="partners-container">
          <div>
            <NavLink to="Partenaires">
              <img className="logo-partner" alt="epsi" src={epsi} />
            </NavLink>
          </div>
          <div>
            <NavLink to="Partenaires">
              <img className="logo-partner" alt="bandcamp" src={bandcamp} />
            </NavLink>
          </div>
          <div>
            <NavLink to="Partenaires">
              <img className="logo-partner" alt="deezer" src={deezer} />
            </NavLink>
          </div>
          <div>
            <NavLink to="Partenaires">
              <img className="logo-partner" alt="twitch" src={twitch} />
            </NavLink>
          </div>
          <div>
            <NavLink to="Partenaires">
              <img className="logo-partner" alt="spotify" src={spotify} />
            </NavLink>
          </div>
          <div>
            <NavLink to="Partenaires">
              <img className="logo-partner" alt="wwf" src={wwf} />
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Accueil;
