import React from "react";
import { useNavigate, NavLink } from "react-router-dom";
import Timetable from "../components/timetable";

function Planning({ events }) {
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="content">
      <h2>PROGRAMMATION</h2>
      <div className="filter-container">
        <button className="button" onClick={goBack}>
          ARTISTE
        </button>
        <NavLink
          to="#"
          className="button to-planning"
          style={{ backgroundColor: "#ffffb5", color: " #0b0426" }}
        >
          PLANNING
        </NavLink>
      </div>
      <Timetable events={events} />
    </div>
  );
}
export default Planning;
