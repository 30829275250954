import epsi from "../image/epsi.svg";
import bandcamp from "../image/bandcamp.svg";
import deezer from "../image/deezer.svg";
import spotify from "../image/spotify.svg";
import twitch from "../image/twitch.svg";
import wwf from "../image/wwf.svg";

function Partenaires() {
  return (
    <div>
      <h2> NOS PARTENAIRES</h2>
      <div>
        <div className="partner-container">
          <div className="logo-container ">
            <img className="logo-partner " src={epsi} />
          </div>
          <div>
            <p>
              Chez EPSI, école d’informatique, nous envisageons l’apprentissage
              des connaissances liées à l’ingénierie informatique comme un tout
              unique et indissociable. Nous pensons que pour vraiment maîtriser
              le développement d’applications informatiques, il est important
              d’envisager en même temps l’administration de systèmes et de
              réseaux, la gestion de données, le pilotage projet… Nous
              considérons que c’est l’acquisition de l’ensemble de ces
              compétences en plus d’une vision à 360° qui vous permettront de
              devenir expert, d’évoluer durablement dans cette filière et de
              vous connecter à la réalité des entreprises.
            </p>
            <a href="https://www.epsi.fr/" target="_blank" className="to-more">
              En savoir plus &rarr;
            </a>
          </div>
        </div>
        <div className="partner-container">
          <div className="logo-container ">
            <img className="logo-partner " src={bandcamp} />
          </div>
          <div>
            <p>
              Bandcamp est une plateforme de musique en ligne qui permet aux
              artistes de vendre et de promouvoir leur musique directement
              auprès de leurs fans. Les musiciens peuvent télécharger leurs
              morceaux et albums, définir leurs propres tarifs et personnaliser
              leurs pages. Les fans peuvent acheter de la musique, des produits
              dérivés et même découvrir de nouveaux artistes. Bandcamp est connu
              pour son approche conviviale pour les artistes, offrant une part
              de revenus plus élevée aux créateurs par rapport à de nombreuses
              autres plateformes musicales, ce qui en fait un choix populaire
              pour les musiciens indépendants et les petites maisons de disques.{" "}
            </p>
            <a href="https://bandcamp.com/" target="_blank" className="to-more">
              En savoir plus &rarr;
            </a>
          </div>
        </div>
        <div className="partner-container">
          <div className="logo-container ">
            <img className="logo-partner " src={deezer} />
          </div>
          <div>
            <p>
              Deezer est un service de streaming musical qui propose une vaste
              bibliothèque de chansons, de listes de lecture et de podcasts que
              les utilisateurs peuvent diffuser sur divers appareils. Il offre
              une expérience d'écoute personnalisée, en adaptant les
              recommandations musicales en fonction des préférences de
              l'utilisateur. Deezer propose à la fois un accès gratuit financé
              par la publicité et des options d'abonnement premium. Il est
              disponible dans de nombreux pays et a gagné en popularité en tant
              que plate-forme permettant de découvrir, d'apprécier et de
              partager de la musique dans différents genres.{" "}
            </p>
            <a
              href="https://www.deezer.com/fr/"
              target="_blank"
              className="to-more"
            >
              En savoir plus &rarr;
            </a>
          </div>
        </div>
        <div className="partner-container">
          <div className="logo-container ">
            <img className="logo-partner " src={spotify} />
          </div>
          <div>
            <p>
              Spotify est une plateforme de streaming musical réputée pour sa
              vaste bibliothèque de chansons, de podcasts et de contenu audio.
              Les utilisateurs peuvent profiter de ses offres via une
              application conviviale, en choisissant entre des options
              d'abonnement gratuites financées par la publicité ou premium. Le
              service permet aux utilisateurs de créer des listes de lecture, de
              recevoir des recommandations musicales personnalisées et de
              partager facilement leurs morceaux préférés. Spotify a transformé
              l'industrie musicale en offrant aux utilisateurs un moyen pratique
              et polyvalent d'accéder, de découvrir et d'apprécier la musique à
              l'ère numérique.{" "}
            </p>
            <a
              href="https://www.spotify.com/fr/premium/"
              target="_blank"
              className="to-more"
            >
              En savoir plus &rarr;
            </a>
          </div>
        </div>
        <div className="partner-container">
          <div className="logo-container ">
            <img className="logo-partner " src={twitch} />
          </div>
          <div>
            <p>
              Twitch est une plate-forme de diffusion en direct principalement
              axée sur le contenu lié aux jeux vidéo, où les utilisateurs,
              appelés streamers, se diffusent en train de jouer à des jeux,
              d'interagir avec les téléspectateurs et de partager leurs
              intérêts. Les téléspectateurs peuvent regarder ces flux, interagir
              avec le streamer via le chat et même les soutenir avec des
              abonnements ou des dons. Au-delà des jeux, Twitch s'est étendu
              pour inclure des flux de musique, des arts créatifs et divers
              autres intérêts, ce qui en fait une plate-forme diversifiée et
              interactive pour le divertissement en ligne et le développement
              d'une communauté.{" "}
            </p>
            <a
              href="https://www.twitch.tv/"
              target="_blank"
              className="to-more"
            >
              En savoir plus &rarr;
            </a>
          </div>
        </div>
        <div className="partner-container">
          <div className="logo-container ">
            <img className="logo-partner " src={wwf} />
          </div>
          <div>
            <p>
              Le Fonds mondial pour la nature est une organisation non
              gouvernementale mondiale dédiée à la conservation de la nature et
              à la protection de l'environnement. Fondé en 1961, le WWF
              travaille sur un large éventail de questions environnementales,
              notamment la préservation de la faune, la conservation des
              habitats, le développement durable et l'atténuation du changement
              climatique. Elle opère dans plus de 100 pays et collabore avec les
              gouvernements, les entreprises et les communautés pour promouvoir
              la conservation et sensibiliser aux défis environnementaux.{" "}
            </p>
            <a href="https://www.wwf.fr/" target="_blank" className="to-more">
              En savoir plus &rarr;
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Partenaires;
