import React, { useState } from "react";
import { NavLink } from "react-router-dom";

function Infos() {
  const [more, setMore] = useState({
    billet1: false,
    billet2: false,
    billet3: false,
    billet4: false,
    camp1: false,
    camp2: false,
    camp3: false,
    camp4: false,
    autre1: false,
    autre2: false,
  });
  const billetQuestion = [
    {
      title: "JE N'AI PAS REÇU MON BILLET ! ?",
      id: "billet1",
      p: ["Récupérez votre billet en toute autonomie : www.seetickets.com"],
    },
    {
      title: "JE VEUX REVENDRE MON BILLET",
      id: "billet2",
      p: ["Revendez votre billet en toute légalité : www.seetickets.com"],
    },
    {
      title: "JE VEUX ACHETER UN BILLET À UN·E PARTICULIER·E EN TOUTE SÉCURITÉ",
      id: "billet3",
      p: [
        "La loi n° 2012-348 du 12 mars 2012 protège les spectateurs contre la revente de billets par toute société ou personne physique non agréé par le producteur du spectacle. Le festival se réserve le droit d'engager des poursuites si des ventes illégales sont constatées (vous encourez jusque 15000€ d'amende).",
        "Si vous décidez d'acheter un billet en-dehors des circuits légaux, vous risquez d'avoir affaire à un faux ou à une copie et de ne pas pouvoir rentrer sur le site du festival.",
      ],
    },
    {
      title: "PUIS-JE CHANGER LE NOM INSCRIT SUR MON BILLET  ?",
      id: "billet4",
      p: [
        "Vous recevrez un email au printemps 2024 vous indiquant les modalités vous permettant de nommer vos billets (obligatoire). Vous aurez jusqu'à mi-juin 2024 pour nommer chaque billet.",
        "À partir de fin juin 2024, vous recevrez sur votre adresse mail un lien vers votre commande pour télécharger vos billets. Si vous n'avez pas nommé vos billets, ils seront automatiquement attribués au nom de l'acheteur de la commande.",
      ],
    },
  ];
  const campQuestion = [
    {
      title: "L'ACCÈS AU CAMPING EST-IL COMPRIS DANS MON BILLET  ?",
      id: "camp1",
      p: [
        "Non ! Vous devrez réserver votre accès avant le 20 juin 2024.",
        "Sans cet accès spécifique, impossible de vous rendre au camping.",
      ],
    },
    {
      title: "PUIS-JE M'INSTALLER LA VEILLE DU JOUR INDIQUÉ SUR MON BILLET  ?",
      id: "camp2",
      p: [
        "Le camping ouvre jeudi 13 juillet à 10h.",
        "Vous ne pouvez pas vous installer la veille du jour de votre venue, votre accès sera refusé aux entrées du camping.",
      ],
    },
    {
      title:
        "J'AI UN BILLET POUR LE JEUDI ET UN AUTRE POUR LE DIMANCHE (PAR EXEMPLE), PUIS-JE RESTER AU CAMPING  ?",
      id: "camp3",
      p: [
        "Attention, toute sortie est définitive !",
        "Si vous sortez du camping et que vous n'avez pas de réservation pour ce jour-là, vous ne pourrez pas re-rentrer…",
      ],
    },
    {
      title: "JE VIENS EN VAN/CAMPING-CAR, PUIS-JE M'INSTALLER AU CAMPING  ?",
      id: "camp4",
      p: [
        "Non, le camping est réservé aux tentes uniquement.",
        "Vous pourrez vous garer sur le parking réservé aux camping cars",
      ],
    },
  ];
  const autreQuestion = [
    {
      title:
        "J'AI UN FOODTRUCK / JE VOUS PROPOSE MON STAND D'ARTISANAT SUR LE SITE DU FESTIVAL, COMMENT FAIRE  ?",
      id: "autre1",
      p: [
        "Seuls nos partenaires peuvent tenir un stand dans l'enceinte du festival. Nous ne donnons suite à aucune demande.",
      ],
    },
    {
      title: "HELP  ! JE N'AI PLUS DE BATTERIE  !",
      id: "autre2",
      p: [
        "Global Charger propose pendant toute la durée du festival des batteries portables adaptables pour tous les smartphones pour être sûr·e de ne jamais tomber en panne ! Service gratuit, avec caution de 20€.",
      ],
    },
  ];
  const toggleQuestion = (questionId) => {
    setMore((prevMore) => ({
      ...prevMore,
      [questionId]: !prevMore[questionId],
    }));
  };
  return (
    <div>
      <h2>INFOS</h2>
      <div className="infos-container">
        <p>Une question ?</p>
        <p>Nous avons (sûrement) la réponse !</p>
        <h2 className="h2-infos">BILLET</h2>
        <div>
          {billetQuestion.map((q) => (
            <div className="question-container" key={q.id}>
              <div className="question">
                <h3 className="h3-infos">{q.title}</h3>
                <div className="question-toggle-container">
                  <NavLink
                    to="#"
                    className={`question-toggle`}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleQuestion(q.id);
                    }}
                  >
                    <span className="question-toggle-bar "></span>
                    <span
                      className={`question-toggle-bar question-toggle-bar--v ${
                        more[q.id] ? " minus" : ""
                      }`}
                    ></span>
                  </NavLink>
                </div>
              </div>
              <div
                className={`question-toggle-p ${more[q.id] ? "" : " minus"}`}
              >
                {q.p.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </div>
            </div>
          ))}
        </div>
        <h2 className="h2-infos">CAMPING / HEBERGEMENT</h2>
        <div>
          {campQuestion.map((q) => (
            <div className="question-container" key={q.id}>
              <div className="question">
                <h3 className="h3-infos">{q.title}</h3>
                <div className="question-toggle-container">
                  <NavLink
                    to="#"
                    className={`question-toggle`}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleQuestion(q.id);
                    }}
                  >
                    <span className="question-toggle-bar "></span>
                    <span
                      className={`question-toggle-bar question-toggle-bar--v ${
                        more[q.id] ? " minus" : ""
                      }`}
                    ></span>
                  </NavLink>
                </div>
              </div>
              <div
                className={`question-toggle-p ${more[q.id] ? "" : " minus"}`}
              >
                {q.p.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </div>
            </div>
          ))}
        </div>
        <h2 className="h2-infos">AUTRES QUESTIONS</h2>
        <div>
          {autreQuestion.map((q) => (
            <div className="question-container" key={q.id}>
              <div className="question">
                <h3 className="h3-infos">{q.title}</h3>
                <div className="question-toggle-container">
                  <NavLink
                    to="#"
                    className={`question-toggle`}
                    onClick={(e) => {
                      e.preventDefault();
                      toggleQuestion(q.id);
                    }}
                  >
                    <span className="question-toggle-bar "></span>
                    <span
                      className={`question-toggle-bar question-toggle-bar--v ${
                        more[q.id] ? " minus" : ""
                      }`}
                    ></span>
                  </NavLink>
                </div>
              </div>
              <div
                className={`question-toggle-p ${more[q.id] ? "" : " minus"}`}
              >
                {q.p.map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="contact-container">
        <p>Vous n'avez pas trouvé de réponse à votre question ?</p>
        <h2>Contactez-nous</h2>
        <form>
          <div className="your-info">
            <input
              aria-required="true"
              aria-invalid="false"
              placeholder="Votre nom*"
              type="text"
              name="nom"
            ></input>
            <input
              aria-required="true"
              aria-invalid="false"
              placeholder="Votre email*"
              type="text"
              name="mail"
            ></input>
          </div>
          <input
            aria-required="true"
            aria-invalid="false"
            placeholder="Objet*"
            type="text"
            name="objet"
          ></input>
          <textarea
            rows="10"
            aria-required="true"
            aria-invalid="false"
            placeholder="Votre message*"
            name="message"
          ></textarea>
          <button type="submit">ENVOYER</button>
        </form>
      </div>
    </div>
  );
}
export default Infos;
